/* DatePickerStyles.css */
.calendar-container-full-width {
    width: 100%;
}

.calendar-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
}

.react-datepicker {
    width: 100%; /* Ensure calendar stretches to 100% width */
    max-width: 100%;
}

/* Inline styles to align text in the calendar to the center */
.custom-calendar .react-datepicker__month-text,
.custom-calendar .react-datepicker__day-name,
.custom-calendar .react-datepicker__day {
    text-align: center;
}

.react-datepicker__month-container {
    width: 100%;
}

.button-container {
    display: flex;
    justify-content: space-between;
}

.calendar-error-container {
    display: flex;
    flex-direction: column;
}

.error-message {
    color: red;
    margin-top: 8px;
}

.calendar-error-container.error .custom-calendar {
    border: 1px solid red;
}

.green-notification {
    color: #73cb73;
}

.custom-day {
    border: 1px solid #d3d3d3;
    color: #c5c5c5;
}

.free-day {
    border: 1px solid black;
    background-color: #67d167;
    color: white;
}

.disabled-day {
    border: 1px solid #d3d3d3;
    color: #c5c5c5;
    cursor: not-allowed;
}

.selected-day {
    border: 1px solid black;
    background-color: yellow;
    color: black;
}
